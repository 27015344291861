/* eslint-disable no-console */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SuggationBuddy } from './styles';
import { ImageUrl } from '../../utils/constants';
import { isNull } from 'lodash';
import { isEmpty } from 'lodash';
import { isUndefined } from 'lodash';
import { sendFriendRequest, getRejectFriendSuggestion } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import { LoaderContainer } from "../Content/styles";
import Loading from '../Loading';
import{FeedCardWrapper, UserInitialsMain} from '../SocialFeedsV2/styles';


class SuggestionForYouSocial extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      suggestionLists: props.suggestionList,
      initialLength: 3,
      temp: 0
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.suggestionList !== this.props.suggestionList) {
      this.setState({ suggestionLists: this.props.suggestionList });
    }
  }
  sendRequest = (id, status) => {
    const { showAddBuddyPopUp } = this.props;
    showAddBuddyPopUp(id, status);
  }

  rejectRequest = (id) => {
    const { getRejectFriendSuggestion } = this.props;
    getRejectFriendSuggestion(id);
  }

  handleShowAll = () => {
    this.setState((prev) => ({
      showAll: !prev.showAll,
    }))
    if (this.state.temp === 0) {
      this.setState({
        initialLength: this.state.suggestionLists[0].length,
        temp: 1
      })
    } else {
      this.setState({
        initialLength: 3,
        temp: 0
      })
    }
  }

  cancelRequest = (id, status) => {
    const { showAddBuddyPopUp2 } = this.props;
    showAddBuddyPopUp2(id, status);
  }

  fallBack = (e) => {
    e.target.src = "/public/images/neutral_avatar.svg";
  };

  containsDefaultPng = (str) => {
    const defaultPngFound = str?.includes("default.png");
    return defaultPngFound;
  }

  getInitials = (inputString) =>{
    const words = inputString.split(' ');
    const initials = words.map((word) => word[0].toUpperCase());
    return initials.join('');
  }

  render() {
    const { suggestionLists } = this.state;
    let suggestionUserData=[]
    if (isUndefined(suggestionLists)) {
      return (<LoaderContainer><Loading /></LoaderContainer>)
    }
    if(!isNull(suggestionLists) && !isEmpty(suggestionLists)){
      suggestionLists[0]?.map((data)=>{
        if(data.request_status!="Pending"){
          suggestionUserData.push(data)
        }
      })
    }
    return (
      <>
        {
          suggestionUserData && suggestionUserData.length===0 ? null :
            (
              <FeedCardWrapper padding="12px 12px 4px 12px">
                <div className='heading'>
                  <div className='title' onClick={()=>this.props.history.push({pathname: '/community'})}>Suggestions For You</div>
                </div>
                <div className='wrapper'>
                  {suggestionUserData?.length>0 && suggestionUserData?.slice(0,5).map((user, index) => (
                    <SuggationBuddy key={index}>
                      <div className='image'>
                        {!this.containsDefaultPng(user.profile_image)?
                          <img src={ImageUrl + "/" +user.profile_image}/>:
                          <UserInitialsMain font>{`${this.getInitials(user?.name)}`}</UserInitialsMain>}
                      </div>
                      <div className='user-details'>
                        <div className='name'>{user.name}</div>
                        <div className='department'>{user.department}</div>
                        <div className='interestWrapper'>
                          {user && user.wellness_interest&& user.wellness_interest.slice(0, 5).map((interest, index) => (
                            <div className='interest' key={index}>
                              <img src={`${ImageUrl}/${interest.interest_icon}`} />
                            </div>
                          )) }
                        </div>
                        {user.request_status != "Pending"&&<div className='connect' onClick={() => this.sendRequest(user.uid, user.request_status)}>
                          <img src={ImageUrl +"/social-feed/add-buddy.svg"} />Connect
                        </div>}
                      </div>
                    </SuggationBuddy>
                  ))}
                </div>
              </FeedCardWrapper>
            )
        }
      </>
    );
  }
}

SuggestionForYouSocial.propTypes = {
  suggestionList: PropTypes.array,
  history: PropTypes.object.isRequired,
  sendSuggestionFriendRequest: PropTypes.func,
  sendFriendRequest: PropTypes.func,
  showAddBuddyPopUp: PropTypes.func,
  showAddBuddyPopUp2: PropTypes.func,
  t: PropTypes.func,
  getRejectFriendSuggestion: PropTypes.func,
}

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});
const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: (data) => dispatch(sendFriendRequest(data)),
  getRejectFriendSuggestion: (data) => dispatch(getRejectFriendSuggestion(data))

})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SuggestionForYouSocial));